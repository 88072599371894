import {Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';
import {map, Observable} from 'rxjs';
import {ContactInterface, PatientInterface} from '../+state/users/users.models';


@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private apiClient: ApiClient,
  ) {}

  public getPatients(): Observable<PatientInterface[]> {
    return this.apiClient.get<PatientInterface[]>('/api/patients').pipe(
      map(response => {
        return response['hydra:member'];
      }),
    );
  }

  public getPatientByID(id: string): Observable<PatientInterface> {
    return this.apiClient.get<PatientInterface>(`/api/patients/${id}`).pipe(
      map(response => {
        return response;
      }),
    );
  }

  public updateContactsPatient(id: string, contactIris: string[]): Observable<PatientInterface> {
    return this.apiClient.patch<PatientInterface>(`/api/patients/${id}`, {
      'contacts': contactIris,
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }
}